// AppContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import jwtDecode from "jwt-decode";
import { useNavigate } from 'react-router-dom';

const AppContext = createContext();

const AppProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [profileData, setProfileData] = useState({});
    const navigate = useNavigate(); // Get the navigation function

    const login = () => {
        setIsAuthenticated(true);
    };


    useEffect(() => {
        const token = localStorage.getItem('token') || '';
        if (token) {
            const decodedToken = token && jwtDecode(token);
            const currentDate = new Date();

            if (decodedToken.exp * 1000 < currentDate.getTime()) {
                localStorage.removeItem('token');
                setIsAuthenticated(false);

                navigate('/login')
            } else {
                setIsAuthenticated(true);
                navigate('/dashboard')
            }
        }
    }, []);


    const logout = () => {
        localStorage.removeItem('token');
        setIsAuthenticated(false);
    };

    return (
        <AppContext.Provider value={{ isAuthenticated, profileData, setProfileData, login, logout }}>
            {children}
        </AppContext.Provider>
    );
};

const useAppContext = () => {
    const context = useContext(AppContext);
    if (context === undefined) {
        throw new Error('useAppContext must be used within an AppProvider');
    }
    return context;
};

export { AppProvider, useAppContext };
