
import { LoadingButton } from '@mui/lab';

import { useState, useEffect } from 'react';
// @mui
import {

    Stack,

    TextField,

} from '@mui/material';
// components



// ----------------------------------------------------------------------

const url = 'https://api-v3.smartsol.mk/profile';
export default function ProfilePage() {
    const [data, setData] = useState('');
    const [loading, setLoading] = useState('');

    const [formData, setFormData] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Start by setting loading state to true
                setLoading(true);

                // Make a fetch request
                const token = localStorage.getItem('token');

                const response = await fetch(`${url}`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });

                // Check if the request was successful (status code 2xx)
                if (!response.ok) {
                    throw new Error('Request failed');
                }

                // Parse the response JSON
                const result = await response.json();

                // Update the state with the fetched data
                setData(result);
                setFormData(result);
            } catch (error) {
                // If an error occurs, set the error state
                console.log('error');
            }
        };

        // Call the fetch function
        fetchData();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleClick = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await fetch(url, {
                method: 'PUT',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (!response.ok) {
                console.error('failed');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    if (!formData) {
        return null;
    }

    return (
        <>
            <Stack spacing={3}>
                <TextField
                    name="firstName"
                    label="First name"
                    defaultValue={formData.firstName}
                    onChange={(e) => handleChange(e)}
                />

                <TextField
                    name="lastName"
                    label="Last name"
                    defaultValue={formData.lastName}
                    onChange={(e) => handleChange(e)}
                />

                <TextField
                    name="email"
                    label="Email"
                    defaultValue={formData.email}
                    onChange={(e) => handleChange(e)}
                />

                <TextField name="phone" label="Phone" defaultValue={formData.phone} onChange={(e) => handleChange(e)} />

                <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleClick}>
                    Save data
                </LoadingButton>
            </Stack>
        </>
    );
}
