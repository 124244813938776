import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Helmet } from 'react-helmet-async';
import { styled } from '@mui/material/styles';
import { Link, Stack, Container, Typography, IconButton, InputAdornment, TextField, Checkbox } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import useResponsive from '../hooks/useResponsive';
import Logo from '../components/logo';
import Iconify from '../components/iconify';

// ----------------------------------------------------------------------
const loginUrl = 'http://api.smartsol.mk:8010/auth/register';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        display: 'flex',
    },
}));

const StyledSection = styled('div')(({ theme }) => ({
    width: '100%',
    maxWidth: 480,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    boxShadow: theme.customShadows.card,
    backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
}));


export default function RegisterPage() {
    const navigate = useNavigate();

    const [showPassword, setShowPassword] = useState(false);

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleClick = async () => {
        try {
            const response = await fetch(loginUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                navigate('/login', { replace: true });
            } else {
                console.error('Login failed');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };


    const mdUp = useResponsive('up', 'md');

    return (
        <>
            <Helmet>
                <title> Register </title>
            </Helmet>

            <StyledRoot>
                <Logo
                    sx={{
                        position: 'fixed',
                        top: { xs: 16, sm: 24, md: 40 },
                        left: { xs: 16, sm: 24, md: 40 },
                    }}
                />

                {mdUp && (
                    <StyledSection>
                        <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
                            Hi, Welcome Back
                        </Typography>
                        <img src="/assets/illustrations/illustration_login.png" alt="login" />
                    </StyledSection>
                )}

                <Container maxWidth="sm">
                    <StyledContent>
                        <Typography variant="h4" gutterBottom>
                            Register to SmartSol dashboard
                        </Typography>


                        <>
                            <Stack spacing={3}>
                                <TextField
                                    defaultValue={formData.username}
                                    onChange={(e) => handleChange(e)}
                                    name="firstName"
                                    label="First name"
                                />

                                <TextField
                                    defaultValue={formData.username}
                                    onChange={(e) => handleChange(e)}
                                    name="lastName"
                                    label="Last name"
                                />

                                <TextField
                                    defaultValue={formData.username}
                                    onChange={(e) => handleChange(e)}
                                    name="email"
                                    label="Email address"
                                />

                                <TextField
                                    name="password"
                                    label="Password"
                                    defaultValue={formData.password}
                                    onChange={(e) => handleChange(e)}
                                    type={showPassword ? 'text' : 'password'}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Stack>

                            <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
                                <Checkbox name="remember" label="Remember me" />
                                <Link variant="subtitle2" underline="hover">
                                    Forgot password?
                                </Link>
                            </Stack>

                            <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleClick}>
                                Register
                            </LoadingButton>
                        </>

                    </StyledContent>
                </Container>
            </StyledRoot>
        </>)
}
