
import { LoadingButton } from '@mui/lab';

import { useState, useEffect } from 'react';
// @mui
import {

    Stack,

    TextField,

} from '@mui/material';
// components



// ----------------------------------------------------------------------

const url = 'https://api-v3.smartsol.mk/lights';
export default function AddDevicePage() {

    const [formData, setFormData] = useState({
        uid: "",
        devEui: "",
        name: "",
        description: "",
        latitude: "",
        longitude: "",
        tags: ""
    });



    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleClick = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (!response.ok) {
                console.error('failed');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    if (!formData) {
        return null;
    }

    return (
        <>
            <Stack spacing={3}>
                <TextField
                    name="uid"
                    label="UID"
                    defaultValue={formData.uid}
                    onChange={(e) => handleChange(e)}
                />

                <TextField
                    name="devEui"
                    label="devEui"
                    defaultValue={formData.devEui}
                    onChange={(e) => handleChange(e)}
                />

                <TextField
                    name="name"
                    label="Name"
                    defaultValue={formData.name}
                    onChange={(e) => handleChange(e)}
                />


                <TextField
                    name="description"
                    label="Description"
                    defaultValue={formData.description}
                    onChange={(e) => handleChange(e)}
                />


                <TextField
                    name="latitude"
                    label="Latitude"
                    defaultValue={formData.latitude}
                    onChange={(e) => handleChange(e)}
                />


                <TextField
                    name="longitude"
                    label="Longitude"
                    defaultValue={formData.longitude}
                    onChange={(e) => handleChange(e)}
                />

                <TextField
                    name="tags"
                    label="Tags"
                    defaultValue={formData.tags}
                    onChange={(e) => handleChange(e)}
                />

                <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleClick}>
                    Save new device
                </LoadingButton>
            </Stack>
        </>
    );
}
