import { useEffect, useState } from 'react';

import { Helmet } from 'react-helmet-async';


import { faker } from '@faker-js/faker';
import styled from '@emotion/styled';
import { Card, Typography, Grid, Container } from '@mui/material';

// @mui
import { useTheme } from '@mui/material/styles';
import { useAppContext } from '../ContextProvider';
import { ReactComponent as ConsumptionSvg } from '../common/icons/consumption.svg';
import { ReactComponent as ConsumptionTodaySvg } from '../common/icons/consumption-today.svg';
import { ReactComponent as ActiveDeviceSvg } from '../common/icons/active.svg';
import { ReactComponent as AllDevicesSvg } from '../common/icons/alldevices.svg';
// components
import Iconify from '../components/iconify';
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
} from '../sections/@dashboard/app';
// @mui
// utils
// import { fShortenNumber } from '../../utils/formatNumber';
// components

// ----------------------------------------------------------------------

const StyledIcon = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
}));
// sections




// ----------------------------------------------------------------------

const dashboardUrl = 'https://api-v3.smartsol.mk/dashboard';

export default function DashboardAppPage() {
  const theme = useTheme();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { setProfileData } = useAppContext();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await fetch('https://api-v3.smartsol.mk/profile', {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        // Check if the request was successful (status code 2xx)
        if (!response.ok) {
          throw new Error('Request failed');
        }

        // Parse the response JSON
        const result = await response.json();

        // Update the state with the fetched data
        setProfileData(result);
      } catch (error) {
        // If an error occurs, set the error state
        console.log('error');
      }
    };

    // Call the fetch function
    fetchData();
  }, []);


  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem('token');  // Replace with your actual token

      try {
        const response = await fetch(`${dashboardUrl}`, {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          }
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const result = await response.json();
        setData(result);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);


  return (
    <>
      <Helmet>
        <title> Dashboard </title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Hi, Welcome back
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            {/* <AppWidgetSummary title="Weekly Sales" total={714000} icon={'ant-design:android-filled'} /> */}

            <Card
              sx={{
                py: 5,
                boxShadow: 0,
                textAlign: 'center'
              }}
            >
              <StyledIcon
              >
                <ConsumptionSvg />
              </StyledIcon>

              <Typography variant="h3">{data?.consumption}</Typography>

              <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
                Consumtion
              </Typography>
            </Card>

          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Card
              sx={{
                py: 5,
                boxShadow: 0,
                textAlign: 'center'
              }}
            >
              <StyledIcon
              >
                <ConsumptionTodaySvg />
              </StyledIcon>

              <Typography variant="h3">{data?.consumption_today}</Typography>

              <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
                Consumtion today
              </Typography>
            </Card>
          </Grid>



          <Grid item xs={12} sm={6} md={3}>
            <Card
              sx={{
                py: 5,
                boxShadow: 0,
                textAlign: 'center'
              }}
            >
              <StyledIcon
              >
                <ActiveDeviceSvg />
              </StyledIcon>

              <Typography variant="h3">{data?.active_devices}</Typography>

              <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
                Active devices
              </Typography>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Card
              sx={{
                py: 5,
                boxShadow: 0,
                textAlign: 'center'
              }}
            >
              <StyledIcon
              >
                <AllDevicesSvg />
              </StyledIcon>

              <Typography variant="h3">{data?.all_devices}</Typography>

              <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
                All devices
              </Typography>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
