import { LoadingButton } from "@mui/lab";
import styled from '@emotion/styled';
import { Button, Card, Container, Grid, Slider, Stack, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";

import { ReactComponent as TurnOnDevice } from '../common/icons/turn-on.svg';
import { ReactComponent as TurnOffDevice } from '../common/icons/turn-off.svg';
import { ReactComponent as SliderSvg } from '../common/icons/slider.svg';

const dashboardUrl = 'https://api-v3.smartsol.mk/lights';

const StyledIcon = styled('div')(({ theme }) => ({
    margin: 'auto',
    display: 'flex',
    borderRadius: '50%',
    alignItems: 'center',
    width: theme.spacing(8),
    height: theme.spacing(8),
    justifyContent: 'center',
    marginBottom: theme.spacing(3),
}));

export default function DevicePage() {

    const [data, setData] = useState(null);
    const [lightStatus, setLightStatus] = useState(null);
    const [dimming, setDimming] = useState(null);
    const { id } = useParams();

    useEffect(() => {
        const fetchData = async () => {
            const token = localStorage.getItem('token');  // Replace with your actual token

            try {
                const response = await fetch(`${dashboardUrl}/${id}`, {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    }
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const result = await response.json();
                setData(result);
                setFormData(result);
                setLightStatus(result.light_status);
            } catch (error) {
                console.log(error)
            }
        };

        fetchData();
    }, []);


    const [formData, setFormData] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const updateDevice = async () => {
        const token = localStorage.getItem('token');
        await fetch(`${dashboardUrl}/${id}`, {
            method: 'PUT',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formData),
        }).then((response) => {
            if (response.ok) {
                return response.json();
            }
            throw new Error(
                `Product list API: Something went wrong: ${response.status}`
            );
        })
            .catch((error) => {
                console.error(error);
            });

    };


    const manipulateLight = async (action, dim) => {
        const dimming = dim >= 0 ? { 'dim': dim } : null;
        const token = localStorage.getItem('token');
        try {
            const res = await fetch(`${dashboardUrl}/${formData?.devEui}/action/${action}`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(dimming),
            });

            if (!res.ok) {
                throw new Error(`HTTP error! Status: ${res.status}`);
            }

            if (action === 'turn-on') {
                setLightStatus('ON');
            }

            if (action === 'turn-off') {
                setLightStatus('OFF');
            }

            if (action === 'dim' && dim !== 0) {
                setLightStatus('ON');
            }

            if (action === 'dim' && dim === 0) {
                setLightStatus('OFF');
            }



        } catch (error) {
            console.error('Error:', error);
        }
    };


    if (!data) {
        return null;
    }

    return (
        <>


            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Light actions
                    </Typography>


                </Stack>

                <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={12} sm={6}>
                        <Card
                            sx={{
                                boxShadow: 0,
                                textAlign: 'center',
                                padding: 4,
                                cursor: lightStatus === 'ON' ? 'inherit' : 'pointer',
                                opacity: lightStatus === 'ON' ? 0.4 : 1,
                            }}



                        >
                            <StyledIcon
                            >
                                <TurnOnDevice />
                            </StyledIcon>




                            <Button disabled={lightStatus === 'ON'} onClick={() => {
                                manipulateLight('turn-on');
                            }} variant='outlined'>
                                <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
                                    Turn ON the light
                                </Typography>
                            </Button>
                        </Card>

                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Card
                            sx={{
                                boxShadow: 0,
                                textAlign: 'center',
                                padding: 4,
                                cursor: lightStatus === 'OFF' ? 'inherit' : 'pointer',
                                opacity: lightStatus === 'OFF' ? 0.4 : 1,
                            }}


                        >
                            <StyledIcon
                            >
                                <TurnOffDevice />
                            </StyledIcon>


                            <Button onClick={() => {

                                manipulateLight('turn-off');


                            }} disabled={lightStatus === 'OFF'} variant='outlined'>
                                <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
                                    Turn OFF the light
                                </Typography>
                            </Button>
                        </Card>

                    </Grid>


                    <Grid item xs={12} sm={6}>
                        <Card
                            sx={{
                                boxShadow: 0,
                                textAlign: 'center',
                                padding: 4
                            }}


                        >
                            <StyledIcon
                            >
                                <SliderSvg />
                            </StyledIcon>




                            <Slider
                                aria-label="Dimming"
                                defaultValue={formData?.dim}
                                onChange={(e) => setDimming(e.target.value)}
                                valueLabelDisplay="auto"
                                shiftStep={30}
                                step={10}
                                marks
                                min={0}
                                max={100}
                            />



                            <Button onClick={() => {
                                manipulateLight('dim', dimming);
                                setLightStatus(dimming === 0 ? 'OFF' : 'ON');
                            }} variant='outlined'>
                                <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
                                    Dimming light
                                </Typography>
                            </Button>
                        </Card>

                    </Grid>


                </Grid>






                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Device {data?.uid}
                    </Typography>

                </Stack>


                <Stack spacing={3}>
                    <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                defaultValue={data?.uid}
                                onChange={(e) => handleChange(e)}
                                name="uid"
                                label="UID"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                defaultValue={data?.devEui}
                                onChange={(e) => handleChange(e)}
                                name="devEui"
                                label="devEUI"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                defaultValue={data?.name}
                                onChange={(e) => handleChange(e)}
                                name="name"
                                label="Name"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                defaultValue={data?.description}
                                onChange={(e) => handleChange(e)}
                                name="description"
                                label="Description"
                                fullWidth
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <TextField
                                defaultValue={data?.latitude}
                                onChange={(e) => handleChange(e)}
                                name="latitude"
                                label="Latitude"
                                fullWidth
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <TextField
                                defaultValue={data?.longitude}
                                onChange={(e) => handleChange(e)}
                                name="longitude"
                                label="Longitude"
                                fullWidth
                            />
                        </Grid>


                        <Grid item xs={12} sm={6}>
                            <TextField
                                defaultValue={data?.tags}
                                onChange={(e) => handleChange(e)}
                                name="tags"
                                label="Tags"
                                fullWidth
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={updateDevice}>
                                Save
                            </LoadingButton>
                        </Grid>



                    </Grid>


                </Stack>
            </Container >
        </>
    );
}
