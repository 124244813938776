import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/iconify';
import { useAppContext } from '../../../ContextProvider';

// ----------------------------------------------------------------------
const loginUrl = 'https://api-v3.smartsol.mk/auth/authenticate';

export default function LoginForm() {
  const navigate = useNavigate();

  const { login } = useAppContext();
  const [showPassword, setShowPassword] = useState(false);

  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleClick = async () => {
    const response = await fetch(loginUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData),
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error(
        `Product list API: Something went wrong: ${response.status}`
      );
    })
      .catch((error) => {
        console.error(error);
      });

    if (response.token) {
      login();
      localStorage.setItem('token', response.token);
      navigate('/dashboard');
    }
  };

  return (
    <>
      <Stack spacing={3}>
        <TextField
          defaultValue={formData.username}
          onChange={(e) => handleChange(e)}
          name="email"
          label="Email address"
        />

        <TextField
          name="password"
          label="Password"
          defaultValue={formData.password}
          onChange={(e) => handleChange(e)}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <Checkbox name="remember" label="Remember me" />
        <Link variant="subtitle2" underline="hover">
          Forgot password?
        </Link>
      </Stack>

      <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleClick}>
        Login
      </LoadingButton>
    </>
  );
}
