import { Navigate, useRoutes, useNavigate } from 'react-router-dom';
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
import UserPage from './pages/UserPage';
import LoginPage from './pages/LoginPage';
import RegisterPage from './pages/RegisterPage';
import Page404 from './pages/Page404';
import DashboardAppPage from './pages/DashboardAppPage';
import { useAppContext } from './ContextProvider';
import ProfilePage from './pages/ProfilePage';
import DevicePage from './pages/DevicePage';
import AddDevicePage from './pages/AddDevicePage';

export default function Router() {
  const navigate = useNavigate(); // Get the navigation function
  const { isAuthenticated } = useAppContext();

  const routes = useRoutes([
    {
      path: '/dashboard',
      element: isAuthenticated ? <DashboardLayout /> : <Navigate to="/login" />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'app', element: <DashboardAppPage /> },
        { path: 'devices', element: <UserPage /> },
        { path: 'device/:id', element: <DevicePage /> },
        { path: 'profile', element: <ProfilePage /> },
        { path: 'device/add', element: <AddDevicePage /> },
      ],
    },
    {
      path: 'login',
      element: !isAuthenticated ? <LoginPage /> : <Navigate to="/dashboard/app" />,
    },
    {
      path: 'register',
      element: !isAuthenticated ? <RegisterPage /> : <Navigate to="/dashboard/app" />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '/',
      element: <Navigate to="/login" replace />,
    },
  ]);

  return routes;
}
